import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c464539e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_containerNav = _resolveComponent("containerNav")!
  const _component_container_header = _resolveComponent("container-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_containerNav, { style: {"flex-shrink":"0"} }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_container_header),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.includeList }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1032, ["include"]))
        ]),
        _: 1
      })
    ])
  ]))
}