/*
    Description: 请求配置
    Use: api内其他文件
*/
import axios, { AxiosRequestConfig } from 'axios'
import { ElMessage, ElLoading, ILoadingInstance } from 'element-plus'
import global from '@/utils/global'
import router from '@/router'

export const baseURL = process.env.VUE_APP_BASE_URL
let taskNum = 0
let loading: ILoadingInstance
// 初始化
const request = axios.create({
    baseURL
})

// 定义config数据类型
export interface RequestConfigDto extends AxiosRequestConfig {
    isLoading?: boolean
}

// 配置axios
request.interceptors.request.use(
    (config: RequestConfigDto) => {
        config.headers = config.headers || {}
        if (global.token) {
            config.headers['Access-Token'] = global.token
        }
        if (config.isLoading !== false) {
            taskNum += 1
            if (taskNum === 1) {
                loading = ElLoading.service({
                    fullscreen: false,
                    background: 'rgba(0, 0, 0, 0.5)'
                })
            }
        }
        return config
    },
    error => {
        console.error('请求错误：', error.response)
        return Promise.reject(error.response)
    }
)

// 路由响应拦截
// http response 拦截器
request.interceptors.response.use(
    response => {
        if (typeof response.data === 'string') {
            return response.data
        }
        if ((response.config as RequestConfigDto).isLoading !== false) {
            taskNum -= 1
            if (taskNum === 0) {
                loading.close()
            }
        }
        let data = {}
        switch (response.data.status) {
        case 401:
            loading.close()
            router.push({
                path: '/login'
            })
            return Promise.reject(response.data)
        case 404:
            loading.close()
            ElMessage.error('网络请求错误，请稍后重试或联系管理员！')
            return Promise.reject(response.data)
        case 200:
            if (response.data.data !== undefined) {
                data = response.data.data
            } else {
                data = response.data
            }
            return data
        default:
            loading.close()
            ElMessage(
                {
                    showClose: true,
                    message: response.data.message ||
                      '网络请求错误，请稍后重试或联系管理员！',
                    type: 'error',
                    duration: 8000
                }
            )
            return Promise.reject(response.data)
        }
    },
    error => {
        loading.close()
        const msg =
            error.response?.data?.message ?? '网络连接错误，请检查网络后重试！'
        ElMessage.error(msg)
        return Promise.reject(error.response.data) // 返回接口返回的错误信息
    }
)

export default request
