import request from './request'

// 获取用户基本信息
export interface GetUserInfoResDto {
  roles: {
    isSuper: number,
    permissions: []
  },
  userInfo: {
    avatar: string
    'client_user_id': number
    'is_super': number
    'last_login_time': number
    mobile: string
    'real_name': string
  }
}

export function getUserInfo (): Promise<GetUserInfoResDto> {
    return request.get('/client.user/info')
}

// 获取菜单
export interface UserMenuDto {
  name: string
  path: string
  hidden: boolean
  redirect: string
  alwaysShow: boolean
  meta: {
    title: string
    icon: string
    noCache: boolean
  }
  children?: UserMenuDto
}

export function getMenu (): Promise<UserMenuDto[]> {
    return request.get('/getRouters')
}

// 获取可切换公司列表
export function getUserCompanyList (): Promise<{
  client_name: string,
  client_user_id: number,
  client_create_time: string,
}[]> {
    return request.get('/client.user/switchList')
}

// 切换公司获取TOKEN
export function getUserToken (data: { clientUserId: number }): Promise<{ token: string }> {
    return request.post('/client.user/doSwitch', data)
}

// 获取验证码
export function getSms (params: { mobile: string }): Promise<void> {
    return request.get('/passport/sendSmsCode', {
        params
    })
}

// 登录
export interface LoginDto {
  mobile: string
  smsCode: string
}

interface LoginResDto {
  clientUserId: number
  token: string
}

export function login (data: LoginDto): Promise<LoginResDto> {
    return request.post('/passport/login', data)
}

// 获取用户所在公司信息
export interface UserCompanyInfo {
  name: string,
  project: {
    'name': string,
    'logo': string,
    'logo_white': string,
    'logo_square': string,
    'mp_qrcode': string
  }
}

export function getUserCompany (): Promise<{ clientInfo: UserCompanyInfo }> {
    return request.post('/client/info')
}

// 公司名称自动完成
export function companyNameAuto (params: { search: string }): Promise<string[]> {
    return request.get('/company/autoComplete', {
        params
    })
}

// 文件上传
export function fileUpload (data: FormData, config = {}): Promise<{
  fileInfo: {
    'file_name': string
    'external_url': string
    'preview_url': string
    'file_id': string
  }
}> {
    return request.post('/upload/file', data, config)
}
